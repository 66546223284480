<template>
  <v-autocomplete
    ref="autocomplete"
    :key="selectedDestinations.length"
    class="destination"
    :value="selectedDestinations"
    @input="updateValue($event)"
    :search-input.sync="searchText"
    :loading="isLoading"
    :items="destinationsList"
    :error-messages="localErrorMessages"
    item-text="name"
    :label="label"
    :no-data-text="$t('main.NO_RESULTS_FOUND')"
    :menu-props="{closeOnContentClick:true, disabled: menuIsDisabled}"
    hide-details
    auto-select-first
    open-on-clear
    return-object
    multiple
    chips
  >
    <template v-slot:selection="data">
      <v-chip
        color="#003B711F"
        style="border-radius: 10px;"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        label
        small
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ shortenName(data.item.name) }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="data.item.destinationType == 'COUNTRY'">{{ $t('main.COUNTRY') }}</span>
          <span v-if="data.item.destinationType == 'REGION'">{{ $t('main.REGION') }}</span>
          <span v-if="data.item.destinationType == 'AIRPORT'">{{ $t('main.AIRPORT') }}</span>

        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from "debounce"
import moment from "moment"
import {mapState, mapActions, mapMutations} from "vuex";

export default {
  name: "DestinationLookupIdMultiSelect",
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceQueryLookupDestination = debounce((stext) => {
      return this.queryLookupDestination(stext)
    }, 500)
  },
  mounted() {
    this.isLoading = false // TODO
    this.localErrorMessages = this.errorMessages

    if (this.autocompleteForm) {
        this.destinations = this.savedSelectedCountries
        this.selectedDestinations = this.savedSelectedCountries
        this.updateValue(this.savedSelectedCountries)
    }
  },
  props: {
    value: [],
    label: {
      type: String,
      default: ""
    },
    errorMessages: null
  },
  data() {
    return {
      destinations: [], // TODO TODO TODO TODO TODO TODO TODO TODO TODO
      selectedDestinations: [],
      maxSelectedLength: 10,
      searchText: "",
      lookupResult: [],
      isLoading: false,
      localErrorMessages: null
    }
  },
  computed: {
    ...mapState({
      autocompleteForm: state => state.uiStore.autocompleteForm,
      savedSelectedCountries: state => state.uiStore.selectedCountries,
      destinationLookupHistory: state => state.uiStore.destinationLookupHistory,
    }),
    menuIsDisabled(){
      return this.selectedDestinations.length >= this.maxSelectedLength
    },
    destinationLookupHistoryByGroup() {
      if (this.destinationLookupHistory.length === 0) {
        return []
      }

      let entries = []
      let seenEntries = []
      let historyCount = 0

      let lastDay = null
      for (let lookupItem of this.destinationLookupHistory) {
        let seenId = lookupItem.destinationType + '#' + lookupItem.id

        if (historyCount > 20) {
          continue
        }

        if (seenEntries.indexOf(seenId) !== -1) {
          continue
        }

        entries.push(lookupItem)
        seenEntries.push(seenId)
        historyCount++
      }

      return entries
    },
    destinationsList() {
      if (this.searchText === "" || this.searchText == null) {
        if (this.destinationLookupHistoryByGroup.length === 0) {
          return []
        }

        return [
            { header: this.$t('main.SEARCH_HISTORY') },
            { divider: true },
            ...this.destinationLookupHistoryByGroup,
            ...this.destinations
        ]
      }
      return this.destinations
    }
  },
  methods: {
    ...mapMutations({
      addDestinationLookupHistory: 'uiStore/ADD_DESTINATION_LOOKUP_HISTORY'
    }),
    async queryLookupDestination(searchText) {
      // We only need to search when there is no selectedDestination
      // If we do, we might reset an already choosen selectedDestination!
      if (searchText != null && searchText != "") {
        this.isLoading = true
        let result = await this.$destinationRepository.lookupDestination(searchText)
        this.isLoading = false

        // Multiple searches might happen, even with debounce
        // We only use the result of this query if the request still matches
        // the current search text in the autocomplete input
        if (searchText == this.searchText) {
          if (this.selectedDestinations){
            this.destinations = [...this.selectedDestinations]
          } else {
            this.destinations = []
          }

          this.destinations.push(...result.destinations)

          // we add the current entries at the end of the list, or the already selected
          // entries would be lost. For whatever reason, you can't have selected entries that
          // are not in the "items" list...
          if (Array.isArray(result.selectedDestinations)) {
            for (let destination of result.selectedDestinations) {
              this.destinations.push(destination);
            }
          }

          if (this.autocompleteForm) {
            this.destinations = this.destinations.concat(this.savedSelectedCountries);
          }
        }
      }
    },
    shortenName(name) {
      let short = name.substr(0, 20)
      if (short != name) {
        short = short + "..."
      }
      return short
    },
    resetSelectedDestinations() {
      this.destinations = Array.isArray(this.value) ? [...this.value] : [];
      this.selectedDestinations = Array.isArray(this.value) ? [...this.value] : []
    },
    remove(item) {
      this.selectedDestinations = this.selectedDestinations.filter(sitem => sitem.id !== item.id)
      this.searchText = ""

      this.updateValue(this.selectedDestinations)
    },
    updateValue(items) {
      this.localErrorMessages = null
      let dataToSend = items.length > 0 ? items : null;
      this.$emit('input', dataToSend)

      items.forEach(item => {
        const isItemInHistory = this.destinationLookupHistory.some(historyItem => historyItem.id === item.id);
        if (!isItemInHistory) {
          this.addDestinationLookupHistory(item);
        }
      })

    },
  },
  watch: {
    errorMessages() {
      this.localErrorMessages = this.errorMessages
    },
    value() {
      this.resetSelectedDestinations()
    },
    searchText() {
      this.debounceQueryLookupDestination(this.searchText)
    }
  }
}
</script>

<style scoped>
  .destination /deep/ .v-select__selections{
    max-height: 42px;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow-y: hidden;
    flex: initial;
    justify-content: center;
  }

  .destination /deep/ .v-select__selections .v-chip {
    display: inline-flex;
  }

  .destination /deep/ .v-select__selections input {
    max-height: 32px;
    width: 320px;
  }

  .destination /deep/ .v-select__selections::-webkit-scrollbar {
    display: none;
  }

  .destination /deep/ .v-chip__close {
    font-size: 14px !important;
  }

  .destination /deep/ .v-chip__close.v-icon {
    color: #003b71;
  }
</style>