<template>
  <div class="search-bar" :class="{'search-bar-result': isResultPage}">
    <v-list class="travel-purpose" shaped>
      <v-subheader>{{ $t('main.SEARCH_BAR_TRAVELTYPE').toUpperCase() }}</v-subheader>
      <v-list-item-group color="primary" v-model="restrictionType" mandatory>
        <v-list-item
          v-for="restriction in restrictionTypes"
          :key="restriction.value"
          :value="restriction.value"
        >
          <v-list-item-icon>
            <Svg-icon type="mdi" :path="restriction.icon"></Svg-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ restriction.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="destination-form">
      <v-row>
        <v-col cols="3">
          <DestinationLookup
            v-model="start"
            :error-messages="startErrorMessages"
            :label="$t('main.SEARCH_BAR_DEPARTURE')"
            :model-name="'departure'"
            :defaultDeparture="true"
          />
        </v-col>
        <v-col cols="3">
          <DestinationLookup
            v-model="transit"
            :label="$t('main.SEARCH_BAR_TRANSIT')"
            :model-name="'transit'"
          />
        </v-col>
        <v-col cols="6">
          <DestinationLookupIdMultiSelect
            v-model="destination"
            :error-messages="destinationErrorMessages"
            :label="$t('main.SEARCH_BAR_DESTINATION')"
          />
        </v-col>
      </v-row>
      <v-row class="flex-nowrap">
        <v-col cols="2" style="min-width: 18%">
          <DatePicker
            v-model="dateStart"
            :min="minDate"
            :label="$t('main.SEARCH_BAR_TRAVEL_DATE_START')"
            :appendIcon="calendarIcon"
          />
        </v-col>
        <v-col cols="2" style="min-width: 18%">
          <DatePicker
            v-model="dateEnd"
            :min="dateStart"
            :max="maxDate"
            :label="$t('main.SEARCH_BAR_TRAVEL_DATE_END')"
            :appendIcon="calendarIcon"
          />
        </v-col>
        <v-col style="min-width: 20%">
          <CountryLookupIdMultiSelect
            v-model="nationality"
            :error-messages="nationalityErrorMessages"
            :label="$t('main.SEARCH_BAR_CITIZENSHIP')"
          />
        </v-col>
        <v-col cols="auto" class="mt-2">
          <v-btn color="primary" @click="search" x-large class="search-button">
            <Svg-icon type="mdi" :path="searchIcon"></Svg-icon> {{ $t('main.SEARCH_ACTION') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
import moment from "moment"
import DestinationLookupIdMultiSelect from '@/components/destination/DestinationLookupIdMultiSelect'
import DestinationLookup from '@/components/destination/DestinationLookup'
import CountryLookupIdMultiSelect from '@/components/destination/CountryLookupIdMultiSelect'
import DatePicker from '@/components/ui/DatePicker'
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiAccountSupervisor, mdiBriefcaseVariant, mdiCalendarMonthOutline} from "@mdi/js";
import { mdilMagnify } from '@mdi/light-js';
import {mapMutations, mapState} from "vuex";

export default {
  name: "DestinationSearchBar",
  components: {
    SvgIcon,
    CountryLookupIdMultiSelect,
    DatePicker,
    DestinationLookup,
    DestinationLookupIdMultiSelect
  },
  props: {
    isResultPage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.autocompleteForm) {
      this.dateStart = this.searchParams.dateStart
      this.dateEnd = this.searchParams.dateEnd
    }
  },
  data() {
    return {
      start: null,
      destination: null,
      transit: null,
      nationality: null,
      minDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().add(3, 'years').format("YYYY-MM-DD"),
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'weeks').format("YYYY-MM-DD"),
      restrictionTypes: [
        {'value': 'TOURISM', 'text': this.$i18n.t('main.PRIVATE_TRAVEL'), 'icon': mdiAccountSupervisor},
        {'value': 'BUSINESS', 'text': this.$i18n.t('main.BUSINESS_TRAVEL'), 'icon': mdiBriefcaseVariant},
      ],
      startErrorMessages: null,
      destinationErrorMessages: null,
      nationalityErrorMessages: null,
      calendarIcon: mdiCalendarMonthOutline,
      searchIcon: mdilMagnify
    }
  },
  computed: {
    ...mapState({
      autocompleteForm: state => state.uiStore.autocompleteForm,
      searchParams: state => state.uiStore.searchParams
    }),
    restrictionType: {
      get() {
        return this.$store.state.uiStore.restrictionType
      },
      set(value) {
        return this.$store.commit("uiStore/SET_RESTRICTIONTTYPE", value)
      }
    }
  },
  methods: {
    ...mapMutations({
      SET_AUTOCOMPLETE_FORM: 'uiStore/SET_AUTOCOMPLETE_FORM',
    }),
    search() {
      var hasErrors = false
      if (this.start === null) {
        hasErrors = true
        this.startErrorMessages = [
          "Required"
        ]
      }
      if (this.destination === null) {
        hasErrors = true
        this.destinationErrorMessages = [
          "Required"
        ]
      }
      if (this.nationality === null) {
        hasErrors = true
        this.nationalityErrorMessages = [
          "Required"
        ]
      }

      if (this.dateStart > this.dateEnd) {
        hasErrors = true
        this.$emit('show-error-message', true)
      }

      if (!hasErrors) {
        var transitId = "0";
        var transitType = "UNDEFINED";
        if (this.transit) {
          transitId = this.transit.id;
          transitType = this.transit.destinationType;
        }

        this.$store.commit('uiStore/UPDATE_FORM_DATA', {
          selectedCountries: this.destination,
          departureCountry: this.start,
          transitCountry: this.transit
        });
        this.SET_AUTOCOMPLETE_FORM(true)

        this.$emit('search', {
          departureId: this.start.id,
          departureType: this.start.destinationType,
          destinationIds: this.destination.map(destination => destination.id),
          destinationsTypes: this.destination.map(destination => destination.destinationType),
          transitId: transitId,
          transitType: transitType,
          citizenshipIds: this.nationality.map(citizenship => citizenship.id),
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          restrictionType: this.restrictionType
        })
      }
    }
  }
}
</script>

<style scoped>
  .search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 250px;
    margin-inline: 8%;
  }

  .search-bar-result {
    margin-inline: 12px;
    height: 200px;
  }

  .destination-form {
    height: 176px;
    flex: 1;
    background: #F7F9FC;
    padding: 10px;
    padding-inline: 20px;
    border-radius: 10px !important;
  }

  .travel-purpose{
    background: #F7F9FC;
    margin-right: 10px;
    width: 216px;
    border-radius: 10px !important;
  }

  .search-button{
    font-size: 14px;
    font-weight: 700;
    line-height: 16.41px;
    letter-spacing: 0.0089em;
    text-align: left;
    padding-inline: 3rem !important;
  }

  .destination-form /deep/ .v-select__slot,
  .destination-form /deep/ .v-input__slot {
    min-height: 42px !important;
  }

</style>