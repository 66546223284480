<template>
  <v-autocomplete
    :key="selectedCountries.length"
    class="nationality"
    :value="selectedCountries"
    @input="updateValue($event)"
    :search-input.sync="searchText"
    :loading="isLoading"
    :items="countriesList"
    :error-messages="localErrorMessages"
    item-text="name"
    :label="label"
    :no-data-text="$t('main.NO_RESULTS_FOUND')"
    :menu-props="{closeOnContentClick:true, disabled: menuIsDisabled}"
    hide-details
    open-on-clear
    return-object
    multiple
    chips
  >
    <template v-slot:selection="data">
      <v-chip
        color="#003B711F"
        style="border-radius: 10px;"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        label
        small
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ shortenName(data.item.name) }}
      </v-chip>
    </template>

    <template v-slot:item="{ item }">
      {{item.name}}
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from "debounce"
import moment from "moment"
import {mapState, mapActions, mapMutations} from "vuex";

export default {
  name: "CountryLookupIdMultiSelect",
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceQueryLookupCountry = debounce((stext) => {
      return this.queryLookupCountry(stext)
    }, 500)
  },
  mounted() {
    this.isLoading = false
    this.countriesFetchAll()
      .then(() => {
        this.resetSelectedCountries();
        if (this.autocompleteForm) {
           this.searchParams.citizenshipIds.forEach(id => {
            let countryId = this.allCountries.find(country => country.id === id);
            this.selectedCountries.push(this.countryItem(countryId))
          })
        } else {
          this.defaultNationality = this.allCountries.find(country => country.iso2 === this.localStorageDefaultNationality);
          if (this.defaultNationality) {
            this.selectedCountries.push(this.countryItem(this.defaultNationality))
          }
        }
        this.updateValue(this.selectedCountries)
      })

    this.localErrorMessages = this.errorMessages
  },
  props: {
    value: [],
    label: {
      type: String,
      default: ""
    },
    errorMessages: null
  },
  data() {
    return {
      maxSelectedLength: 4,
      selectedCountries: [],
      searchText: "",
      lookupResult: [],
      isLoading: false,
      localErrorMessages: null,
      defaultNationality: null,
    }
  },
  computed: {
    ...mapState({
      allCountries: state => state.countriesStore.entries,
      localStorageDefaultNationality: state => state.uiStore.defaultNationality,
      autocompleteForm: state => state.uiStore.autocompleteForm,
      searchParams: state => state.uiStore.searchParams,
      countryLookupHistory: state => state.uiStore.countryLookupHistory,
    }),
    menuIsDisabled(){
      return this.selectedCountries.length >= this.maxSelectedLength
    },
    countryLookupHistoryByGroup() {
      if (this.countryLookupHistory.length === 0) {
        return []
      }

      let entries = []
      let seenEntries = []
      let historyCount = 0

      let lastDay = null
      for (let lookupItem of this.countryLookupHistory) {
        let seenId = lookupItem.destinationType + '#' + lookupItem.id

        if (historyCount > 20) {
          continue
        }

        if (seenEntries.indexOf(seenId) !== -1) {
          continue
        }

        if (lastDay !== moment(lookupItem.lookupDate).format('YYYY-MM-DD')) {
          lastDay = moment(lookupItem.lookupDate).format('YYYY-MM-DD')

          if (moment(lookupItem.lookupDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            entries.push({ header: this.$t('main.TODAY') })
          } else {
            entries.push({ header: moment(lastDay).format('L') })
          }
        }

        entries.push(lookupItem)
        seenEntries.push(seenId)
        historyCount++
      }

      return entries
    },
    countriesList() {
      if (this.searchText === "" || this.searchText == null) {
        if (this.countryLookupHistoryByGroup.length === 0) {
          return []
        }

        return [
          { header: this.$t('main.SEARCH_HISTORY') },
          { divider: true },
          ...this.countryLookupHistoryByGroup,
            ...this.lookupResult
        ]
      }
      return this.lookupResult
    }
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll"
    }),
    ...mapMutations({
      addCountryLookupHistory: 'uiStore/ADD_COUNTRY_LOOKUP_HISTORY'
    }),
    async queryLookupCountry(searchText) {
      // We only need to search when there is no selectedCountry
      // If we do, we might reset an already choosen selectedCountry!
      if (searchText != null && searchText != "") {
        this.isLoading = true
        let result = await this.$destinationRepository.lookupCountry(searchText)
        this.isLoading = false

        if (this.selectedCountries){
            this.lookupResult = [...this.selectedCountries.map(country => this.countryItem(country))]
        } else {
            this.lookupResult = []
        }

        this.lookupResult.push(...result.countries)
      }
    },
    resetSelectedCountries() {
      this.lookupResult = Array.isArray(this.value) ? [...this.value] : [];
      this.selectedCountries = Array.isArray(this.value) ? [...this.value] : [];
    },
    shortenName(name) {
      let short = name.substr(0, 20)
      if (short != name) {
        short = short + "..."
      }
      return short
    },
    remove(item) {
      this.selectedCountries = this.selectedCountries.filter(sitem => sitem.id != item.id)
      this.updateValue(this.selectedCountries)
      this.searchText = ""
    },
    updateValue(items) {
      this.localErrorMessages = null
      let ids = items.length ? items : null;
      this.$emit('input', ids)

      items.forEach(item => {
        const isItemInHistory = this.countryLookupHistory.some(historyItem => historyItem.id === item.id);
        if (!isItemInHistory) {
          this.addCountryLookupHistory(item);
        }
      })
    },
    countryItem(country){
      return {
          id: country.id,
          destinationType: "COUNTRY",
          name: country.nameShort ? country.nameShort : country.name,
      }
    },
  },
  watch: {
    errorMessages() {
      this.localErrorMessages = this.errorMessages
    },
    value() {
      this.resetSelectedCountries()
    },
    searchText() {
      this.queryLookupCountry(this.searchText)
    }
  }
}
</script>

<style scoped>
  .nationality /deep/ .v-select__selections{
    max-height: 42px;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow-y: hidden;
    flex: initial;
    justify-content: center;
  }

  .nationality /deep/ .v-select__selections .v-chip {
    display: inline-flex;
  }

  .nationality /deep/ .v-select__selections input {
    max-height: 32px;
    width: 320px;
  }

  .nationality /deep/ .v-select__selections::-webkit-scrollbar {
    display: none;
  }

  .nationality /deep/ .v-chip__close {
    font-size: 14px !important;
  }

  .nationality /deep/ .v-chip__close.v-icon {
    color: #003b71;
  }
</style>